import React from "react";

const Subpage = (props) => {
  if (!props.isEven) {
    return (
      <article
        id={props.id}
        className="flex sm:flex-row flex-col-reverse overflow-hidden items-end justify-end h-screen w-full sm:max-h-screen"
      >
        <div className="flex flex-col min-h-2/3 h-full text-xs gap-2 justify-center items-center w-full sm:w-2/3 py-10">
          {props.children}
        </div>
        <img
          src={props.image}
          alt={props.alt}
          className="sm:w-1/3 sm:object-cover w-full h-1/3 sm:max-h-full max-h-52 sm:h-full object-cover overflow-hidden sm:shadow-none shadow-xl shadow-rose-50"
        />
      </article>
    );
  }
  return (
    <article
      id={props.id}
      className="flex bg-rose-100 sm:flex-row overflow-hidden flex-col min-h-screen w-full sm:max-h-screen h-screen"
    >
      <img
        className="sm:w-1/3 sm:object-cover w-full sm:h-full h-1/3 max-h-52 sm:max-h-full object-cover overflow-hidden shadow-xl shadow-rose-50"
        src={props.image}
        alt={props.alt}
      />
      <div className="flex flex-col min-h-2/3 h-full gap-2 justify-center items-center w-full sm:w-2/3 py-10">
        {props.children}
      </div>
    </article>
  );
};

export default Subpage;
