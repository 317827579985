import React from "react";
import Subpage from "./Subpage";
import { Tab } from "@headlessui/react";

import Side from "./assets/enlarge_side.png";
import VillaBonn from "./assets/villabonn.png";
import Contact from "./assets/contact.jpg";
import Dresscode from "./assets/dress.jpeg";

export default function App() {
  return (
    <div className="md:container md:mx-auto md:border-x-2 flex flex-col w-screen min-h-screen font-playfair">
      <header className="py-2 flex items-center border-b border-rose-950 bg-white justify-center gap-1 flex-wrap text-center sticky top-0 z-50 text-sm">
        <a href="#home" className="p-2 hover:text-rose-800 text-rose-400">
          Home
        </a>
        <a href="#location" className="p-2">
          Location
        </a>
        <a href="#uebernachten" className="p-2">
          Übernachten
        </a>
        <a href="#dresscode" className="p-2">
          Dresscode
        </a>
        <a href="#wuensche" className="p-2">
          Wunschliste
        </a>
        <a href="#kontakt" className="p-2">
          Kontakt
        </a>
      </header>
      <main className="h-screen relative overflow-scroll">
        <Subpage id="home" image={Side} alt="not found" isEven={false}>
          <h1 className="text-7xl text-rose-800 drop-shadow text-center px-10 sm:-translate-y-1/2">
            Johanna & Robert
          </h1>
          <h3 className="text-2xl text-slate-500 py-2 sm:-translate-y-1/2">
            a wonderful wedding
          </h3>
        </Subpage>
        <Subpage id="location" image={VillaBonn} alt="not_found" isEven={true}>
          <h2 className="text-4xl text-rose-800">Location</h2>
          <p className="px-10">
            Die Hochzeitszeremonie und die anschließende Feier finden statt in
            der
          </p>
          <addr className="font-bold  ">
            Villa Bonn <br />
            Siesmayerstraße 12, <br />
            60323 Frankfurt am Main <br />
          </addr>
          <p className="px-10">
            Die Villa liegt im Frankfurter Westend und ist ca. 1,2 Kilometer von
            der Alten Oper entfernt. Es sind ausreichend Parkplätze am
            Veranstaltungsort vorhanden. Außerdem ist die U-Bahn-Haltestelle
            „Westend“ lediglich drei Gehminuten entfert. Dort halten die Linien
            der U6 und U7. Vom Hauptbahnhof kommend, empfehlen wir Euch entweder
            eine Taxifahrt (etwa 8 Minuten) oder die U4 in Richtung Bockenheimer
            Warte. Von der Haltestelle Bockenheimer Warte sind es ca. 600 Meter
            bis zur Location.
          </p>
        </Subpage>
        <Subpage id="uebernachten" image={Side} alt="not_found" isEven={false}>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="flex flex-col justify-center items-center px-10 gap-2 ">
                <h2 className="text-4xl text-rose-800">Übernachten</h2>
                <p className=" text-base">
                  {" "}
                  In der Nähe der Villa Bonn gibt es zahlreiche
                  Übernachtungsmöglichkeiten. Wir würden Euch empfehlen, so früh
                  wie möglich zu buchen, da die Preise der Hotelzimmer häufig
                  durch verschiedene Messen in die Höhe getrieben werden. Einige
                  Hotels in der Nähe zur Location stellen wir Euch hier vor.
                </p>
              </Tab.Panel>
              <Tab.Panel className="flex flex-col justify-center items-center px-10 gap-2">
                <h3 className="sm:text-xl text-lg font-bold">Motel One</h3>
                <address className="font-bold  text-base not-italic">
                  Motel One Messe
                  <br />
                  Europa-Allee 25 <br />
                  60327 Frankfurt
                  <br />
                </address>
                <p className=" text-base">
                  Entferung zur Location:
                  <br />
                  <span className="font-semibold">Autofahrt ca. 7 Minuten</span>
                  <br />
                  <span className="font-semibold">
                    Nahverkehr: ca. 20 Minuten{" "}
                  </span>
                  <br />{" "}
                  <span className="font-semibold">Fußweg: 24 Minuten </span>
                  <br />
                  Wir haben hier für den 7.9.2024 ein Abrufkontigent (das
                  Doppelzimmer zu 99 Euro, exklusive Frühstück) für euch
                  reserviert. Ihr könnt die Zimmer telefonisch oder per E-Mail
                  unter Nennung der folgenden Reservierungsnummer buchen:
                  541490803. Die Zimmer können bis zum 15.8.2024 gebucht werden.
                  Da sie allerdings nach dem First come, first serve-Prinzip
                  vergeben werden, raten wir zu einer rechtzeitigen Buchung.
                </p>
              </Tab.Panel>
              <Tab.Panel className="flex flex-col justify-center items-center px-10 gap-2">
                {" "}
                <h3 className="sm:text-xl text-lg font-bold">Sofitel</h3>
                <address className="font-bold  text-base not-italic">
                  Sofitel Frankfurt Opera
                  <br />
                  Opernpl. 16 <br />
                  60313 Frankfurt am Main
                </address>
                <p className=" text-base">
                  Preise Doppelzimmer ab 260 Euro Entfernung zur Location:
                  Autofahrt ca. 5 Minuten Nahverkehr ca. 8 Minuten Fußweg ca. 18
                  Minuten Hier wird das Brautpaar schlafen
                </p>
              </Tab.Panel>
              <Tab.Panel className="flex flex-col justify-center items-center px-10 gap-2">
                <h3 className="sm:text-xl text-lg font-bold">
                  Hotel Beethoven
                </h3>
                <address className="font-bold  text-base not-italic">
                  Hotel Beethoven
                  <br />
                  Beethovenstraße 46
                  <br />
                  60325 Frankfurt am Main
                </address>
                <p className=" text-base">
                  Preise Doppelzimmer ab 145 Euro Entfernung zur Location:
                  Autofahrt ca. 4 Minuten Fußweg ca. 7 Minuten 4 Sterne Hotel in
                  direkter Nähe zum Veranstaltungsort.
                </p>
              </Tab.Panel>
              <Tab.Panel className="flex flex-col justify-center items-center px-10 gap-2">
                <h3 className="sm:text-xl text-lg font-bold">Trip Inn Messe</h3>
                <address className="font-bold  text-base not-italic">
                  Trip Inn Messe
                  <br />
                  Beethovenstraße 30
                  <br />
                  60325 Frankfurt
                </address>
                <p className=" text-base">
                  Preise Doppelzimmer ab 104 Euro Entfernung zur Location:
                  Aufofahrt ca. 4 Minuten Nahverkehr ca. 7 Minuten Fußweg ca. 10
                  Minuten Ebenfalls fußläufig zum Veranstaltungsort.
                </p>
              </Tab.Panel>
            </Tab.Panels>
            <Tab.List className="w-full p-4 flex flex-wrap text-base justify-center gap-2">
              <Tab className="bg-rose-100 ui-selected:bg-rose-200 p-2 rounded">
                Übernachten
              </Tab>
              <Tab className="bg-rose-100 ui-selected:bg-rose-200 p-2 rounded">
                Motel One
              </Tab>
              <Tab className="bg-rose-100 ui-selected:bg-rose-200 p-2 rounded">
                Sofietel
              </Tab>
              <Tab className="bg-rose-100 ui-selected:bg-rose-200 p-2 rounded">
                Hotel Beethoven
              </Tab>
              <Tab className="bg-rose-100 ui-selected:bg-rose-200 p-2 rounded">
                Trip Inn
              </Tab>
            </Tab.List>
          </Tab.Group>
        </Subpage>
        <Subpage id="dresscode" image={Dresscode} alt="not_found" isEven={true}>
          <h2 className="text-4xl text-rose-800">Dresscode</h2>
          <p className="px-10">
            Wir haben uns für den Dresscode „Black Tie or formal“ entschieden.
            <br />
            Das bedeutet für Männer: Ein Smoking oder ein formeller dunkler
            Anzug, weißes Hemd und Fliege/Krawatte.
            <br />
            Das bedeutet für Damen: Ein bodenlanges Kleid, ein Cocktailkleid
            oder ein eleganter Hosenanzug.
            <br />
            Wichtig: Wenn Ihr Euch in diesem Dresscode nicht wiederfindet, kommt
            bitte so, wie Ihr Euch am festlichsten fühlt 😊
          </p>
        </Subpage>
        <Subpage id="wuensche" image={Side} alt="not_found" isEven={false}>
          <h2 className="text-4xl text-rose-800">Wunschliste</h2>
          <p className="px-10 text-base">
            Unser größtes Geschenk ist Eure Anwesenheit. Deswegen wünschen wir
            uns keine Geschenke. Für alle, denen es aber dennoch in den Fingern
            kribbelt, wollte wir es so nachhaltig wie möglich gestalten und
            haben hier eine kleine Liste angelegt an Dingen, über die wir uns
            freuen würden:
            <br />
            <br />
            <ul className="flex flex-col px-10 gap-2">
              <li className="first-letter:font-bold first-letter:text-xl">
                Gutscheine für die <a className="font-semibold hover:text-rose-800" href="https://oper-frankfurt.de/de/service/" target="_blank" rel="noopener noreferrer">Oper Frankfurt</a> oder das <a className="font-semibold hover:text-rose-800" href="https://www.schauspielfrankfurt.de/karten-abos/theater-schenken/" target="_blank" rel="noopener noreferrer">Schauspiel Frankfurt</a>.
              </li>
              <li className="first-letter:font-bold first-letter:text-xl">Rosen für den Kübel. Gibt es z.B. <a className="font-semibold hover:text-rose-800" href="https://www.rosen.de/gartenrosen/verwendung/toepfe-und-pflanzgefaesse" target="_blank" rel="noopener noreferrer">hier</a>.</li>
              <li className="first-letter:font-bold first-letter:text-xl">
                Dinge, die unsere Tafel schöner machen, den Wein besser
                schmecken lassen oder uns beim Kochen ins nächste Level bringen,
                haben wir <a className="font-semibold hover:text-rose-800" href="https://wunschliste.lorey.de/de/hochzeit/hochzeit-johanna-robert/" target="_blank" rel="noopener noreferrer">hier</a> aufgelistet.
              </li>
            </ul>
          </p>
        </Subpage>
        <Subpage id="kontakt" image={Contact} alt="not_found" isEven={true}>
          <h2 className="text-4xl text-rose-800">Kontakt</h2>
          <div className="flex flex-col gap-2 px-10">
            <p className="text-base">
              Bei allen Rückfragen rund um die Veranstaltung: <br />
              Johanna Esser 0176/41671889
            </p>
            <p className="text-base">
              Bei Ideen oder Beiträgen für die Feier: <br /> Moritz Esser
              0174/3447027
            </p>
          </div>
        </Subpage>
      </main>
      {/* </container> */}
    </div>
  );
}
